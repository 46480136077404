window._ = require('lodash')
window.Swal = require('sweetalert2')
import Inputmask from "inputmask";

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    window.Chart = require('chart.js/dist/chart')

    window.spin = (element) => {
        return $(`
            <div class="text-center">
                <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            `
        ).appendTo(element)
    }

    window.addSpin = (element) => {
        return $(`<div class="d-flex justify-content-center" id="overlay">
            <div class="spinner-border align-center-spinner text-light" role="status">
                <span class="visually-hidden"></span>
            </div>
        </div>`).appendTo(element);
    }

    window.modalDynamic = (size = 'modal-md') => {
        const modal = $(`
            <div class="modal fade" tabindex="-1" role="dialog">
                <div class="modal-dialog ${size}" role="document">
                    <div class="modal-content">
                    </div>
                </div>
            </div>
        `).appendTo('body')

        modal.on('hidden.bs.modal', function (e) {
            modal.remove()
        })

        return modal
    }

    window.message = (title, text, icon) => {
        return Swal.fire({
            title,
            text,
            icon,
            confirmButtonText: 'Fechar'
        })
    }


    jQuery(function ($){
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        })

        const validation = (erros) =>{
            if(typeof erros.errors != "undefined"){
                $.each(erros.errors, (key, item) => {

                    let errorMsg = $(`<div class="invalid-feedback">
                            ${item[0]}
                        </div>`)

                    let inputEl = $('input[name=' + key + '], select[name=' + key + '], textarea[name=' + key + ']')
                    let labelEl = inputEl.closest('.form-group')
                    inputEl.addClass('is-invalid')
                    labelEl.find('.invalid-feedback').remove()
                    labelEl.append(errorMsg)

                    inputEl.on('focus', () => {
                        inputEl.removeClass('is-invalid')
                        errorMsg.remove()
                    });
                });
            }else{
                const erroMessage = erros.message ? erros.message : 'Ocorreu um error'
                message('Atenção', erroMessage, 'error')
            }
        }

        window.formatInput = () =>{
            new Inputmask({mask:["9999 999999 99999", "9999 9999 9999 9999"], placeholder: "" }).mask($('.credit_card'))
            new Inputmask({mask: '99/99', placeholder: "##/##"}).mask($('.mask-mm-yy'))
        }

        window.formInit = () => {
            $("#form-ajax").on('submit', function(e){
                e.preventDefault()
                const form = $(this)
                const data = form.serialize()
                const loadding = addSpin('.modal-content')

                $.ajax({
                    method: form.attr('method'),
                    url: form.attr('action'),
                    data
                })
                .done((response) => {
                    loadding.remove()
                    message('Sucesso', response.message, 'success').then(()=>{
                        window.location.reload()
                    })

                    $($(this)).each(function(){
                        this.reset()
                    })
                })
                .fail((errors) => {
                    loadding.remove()
                    const error = errors.responseJSON
                    validation(error)
                })
            })
        }

        $('a.ajax-modal').on('click', function(e){
            e.preventDefault()
            let size = $(this).data('size')
            let modal = modalDynamic(size)

            $.get($(this).attr('href'))
            .done((response) => {
                modal.find('.modal-content').html(response)
                modal.modal('show')
                formInit()
                formatInput()
            }).fail((error) =>{
                message('Atenção', 'Ocorreu um erro. Tente novamente', 'error')
            });
        })
    })

} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
